@media (min-width:320px) and (max-width:767px){
  .hero-counter-bg .counter-bg {
    width: 100% !important;
    flex-wrap: wrap;
  }
  
  .hero-counter-bg.spl-ft .counter-bg .counter {
    width: 50%;
  }
  .vision-bg {
    margin-left: 0px;
  }
  
  .cm-navbar-menu-list ul li {
    padding: 0;
    margin: 0;
      margin-right: 0px;
    margin-right: 0px;
    display: inline-block;
    margin-right: 0px;
    width: 100%;
  }
  
  .cm-navbar-menu-list{
    position: absolute;
      top: 80px;
      width: 100%;
      z-index: 100;
  padding: 15px;
  }
  
  .mn-ds-none{
    display: none !important;
  }   
  .navbar-light .navbar-brand img{	
    width:100%;	
  }
  .hero-banner-slider {
    padding: 0px 15px;
  }
  .hero-banner-intro .tt.spl-tt {
    font-size: 30px;
  }
  .logo-bg {
    padding: 10px 15px;
  }
  .testimonial-box-bg {
    padding: 30px 15px;
  }
    
  .hero-about-us.spl-cnt {
    /*padding: 60px 15px 0px 15px;*/
    padding:30px 15px 0px 15px;
  }
  .footer-bg {
    padding: 30px 15px;
  }
  .footer-bg .address-bg h4,.footer-bg .footer-list h4{
    font-size: 20px;
  }
  .footer-bg .footer-list{
    margin-bottom:15px;
  }
  .hero-about-us.spl-hgt{
    padding: 0px 15px;
  }
  .testimonial-box-bg.custom-box-bg .hero-counter-bg .counter-bg{
    flex-direction:column;
  }
  .testimonial-box-bg.custom-box-bg .hero-counter-bg .counter-bg .counter{
    width:100%;
  }
  .testimonial-box-bg.custom-box-bg {
    padding: 15px 15px 15px 15px;
  }
  .hero-banner-intro .tt {
    font-size: 30px;
  }
  .feature-box-list.sql-ft.ad-spl-ft.hgt-box {	
  margin-right: 0px;
  }
  .feature-box-list.sql-ft.ad-spl-ft.hgt-box .list {
    flex-direction: column;
  }
  .feature-box-list.sql-ft.ad-spl-ft.hgt-box .list .rgt {
    margin-left: 0px;
  }
  .feature-box-list.sql-ft.ad-spl-ft.hgt-box .list .rgt h5 {
    font-size: 30px;
  }
  .feature-box-list.sql-ft.ad-spl-ft.hgt-box .list .rgt h6 {
    font-size: 18px;
  }
  .quotes-bg{
  margin-left: 0px;
      padding: 15px 15px 40px 15px;
      font-size: 24px;
    margin-bottom:15px;
  }
  .quotes-bg::before {
    position: relative;
    content: "''";
    font-size: 20px;
  }
  .quotes-bg::after {
    position: relative;
    content: "''";
    font-size: 20px;
  }
  .testimonial-box .list {
    display: flex;
    flex-direction: column;
  }
  .logo-bg img{
    width: 100%;
  }
  .logo-bg a{
    width: 90%;
  }
  .hero-about-us {
    padding: 20px 15px 0px 15px;
  }
  .hero-about-us .about-us-img img{
    margin-bottom:15px;
  }
  .hero-about-us .cnt{
    padding:0px;
  }
  .feature-bg {
    padding: 0px 15px;
  }
  .cm-navbar-menu-list ul li .sub-menu{
    display:block;
    position:static !important;
    margin:10px 0px
  }
  .cm-navbar-menu-list ul li .sub-menu li .sub-more-menu {
    display:block;
    position:static !important;
  }
  .cm-navbar-menu-list ul li .sub-menu li a {
    padding: 3px 10px;
    font-size: 14px;
  }
  .cm-navbar-menu-list ul li a {
    color: #f9f9f9;
    text-decoration: none;
    padding: 5px 0px;
  }
  .hamburger{
  display:block;
  }
  .hero-about-us .cnt .sb-tt.spl-cnt {
    font-size: 16px;
  }
  }