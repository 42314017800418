
body{
	font-size:16px;
	 font-family: "PT Sans", sans-serif;
line-height: 24px;
  letter-spacing: 0.5px;
  color:#101026;
}
.header-bg{

}

.logo-bg{
	padding: 10px 45px;
}

.cm-navbar-menu{
	margin-right: auto;
  align-items: flex-end;
  flex-direction: column;
}

.cm-searchbox{
   border: 0px;
   padding: 0px 15px;
   font-size: 14px;
   margin-right: 0px !important;
   border: 1px solid;
}
.cm-navbar-menu-list{
	padding: 0px 45px;
	
	background-image: linear-gradient(to right, var(--color-primary), var(--color-secondary), var(--color-secondary), var(--color-primary));
  background: #101026;
  /* 
background: linear-gradient(to right,  #2f57ef 0%,#a667ef 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

}
.cm-navbar-menu-list ul{	

  padding: 0px;
  margin:0px;
  list-style:none;
}

.cm-navbar-menu-list ul li {
  padding: 0;
  margin: 0;
    margin-right: 0px;
  display: inline-block;
  margin-right: 30px;
  position:relative;
  margin-bottom: 5px;
}

.cm-navbar-menu-list ul li a{
	color:#f9f9f9;
	text-decoration:none;
	padding: 15px 0px;
  display: inherit;
}
.cm-navbar-menu-list ul li a:hover{
	color: #ffc20d;
}



.hero-banner-slider{
display: flex;
  flex-direction: column;
  background: #000;
min-height: auto;
  background-size: cover;
  background-position: center;
  position: relative;
 padding: 0px 30px;
}

.hero-banner-slider::before{
	position:absolute;
	left:0;
	right:0;
	top:0;
	bottom:0;
	width:100%;
	height:100%;
	content:"";
	z-index:1;
	background: rgba(16, 16, 38, 0.7);
}
.hero-banner-intro{
position: relative;
  z-index: 1;
  color: #f9f9f9;
  margin-top: 5%;
  margin-bottom: 7%;
}

.hero-banner-intro .tt{
font-size: 60px;
  font-weight: bold;
  margin-bottom: 15px;
}
.hero-banner-intro .tt.spl-tt{
font-size: 40px;
}
.hero-banner-intro .sb-tt{
line-height: 1.7;
  font-size: 18px;
  color: #ccc;
}
.hero-banner-content {
background: #101026;
 color:#f9f9f9;
  position: relative;
  z-index: 1;
  padding: 15px;
  font-size: 15px;
}

.hero-banner-content .sb-tt{
	margin-bottom:0px;
}

.hero-about-us{
	padding: 60px 30px 40px 30px;
}

.hero-about-us .tt{
font-size: 26px;	
}

.hero-about-us .tt b{
font-size: 15px;
  width: 100%;
  display: inline-block;
  font-weight: normal;
  text-decoration: underline;
}

.hero-about-us .about-us-img img{
	width:100%;
	
}


.hero-about-us .cnt{
padding: 0px 15px;
  font-size: 16px;
  line-height: 26px;
}
.hero-about-us .cnt.spl-cnt {
	padding: 0px;	
}
.hero-about-us .cnt .sb-tt{
	color: #555;
	text-align: justify;
}


.hero-about-us .cnt .sb-tt.spl-cnt{
font-size: 18px;
  background: #fff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: inset 0 0 15px rgba(0,0,0,0.5);
}

.hero-about-us .cnt .sb-tt.spl-cnt p:last-child{
	margin-bottom:0;	
}
  
.hero-counter-bg{
	display:flex;
	flex-direction:row;
	align-items:center;
}

.hero-counter-bg .img{
width: 300px;
  min-height: 162px;
  background-size: cover;
}

.hero-counter-bg .img img{
width:100%;
}
.hero-counter-bg .counter-bg{
	display: flex;
   /*width: calc(100% - 300px);*/
   width:100%;
}
.hero-counter-bg .counter-bg .counter{
display: flex;
  flex-direction: column;
  text-align: center;
  padding: 30px;
 border: 1px solid rgba(0,0,0,0.2);

  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 30px;
  background: #101026;
  width: 100%;
  color: #fff;
}
.hero-counter-bg .counter-bg .counter h4{
	font-size:26px;
	font-weight:bold;
  font-weight: normal;
  color: #cff7ff;
  /*color: #ffc20d;*/
}
.hero-counter-bg .counter-bg .counter p{
	margin-bottom: 0px;
  font-size: 15px;
  color: #A9A9A9;
}

.hero-counter-bg .counter-bg .counter:nth-child(even){
	  background: #202036;
}

.bg-dark-light{
	background: #202036 !important;
}

.feature-bg{
	padding: 0px 30px;
  margin-bottom: 30px;
}
.feature-box-content .tt{
	font-size:26px;	
}

.feature-box-content .tt b{
font-size: 15px;
  width: 100%;
  display: inline-block;
  font-weight: normal;
  text-decoration: underline;
}

.feature-box-list .list {
display: flex;
  flex-direction: row;
  margin-bottom: 15px;
}


.feature-box-list .list .lft{
width: 100px;
  height: 100px;
  margin-right: 15px;	
}
.feature-box-list .list .lft .img{
width: 100px;
  height: 100px;
  border-radius: 50%;  
  transition: all ease-in-out 0.5s;
}

.feature-box-list .list .rgt h5{
	font-weight: normal;
  margin-top: 10px;
 
  font-size: 17px;
}
.feature-box-list .list .rgt p{
	
	color: #767676;
}


.feature-box-content{
	margin-bottom: 30px;
}
.testimonial-box-bg{	
background: #101026;
  padding: 60px 30px;
  color: #fff;
  padding-right: 20px;
}



.testimonial-box .list {
display: flex;
  flex-direction: row;
  margin-bottom: 15px;
}


.testimonial-box .list .lft{
width: 150px;
  height: 150px;
margin-right: 30px;
}
.testimonial-box .list .lft .img{
width: 150px;
  height: 150px;
  border-radius: 50%;

  transition: all ease-in-out 0.5s;
}

.testimonial-box .list .lft .img:hover{
 
}

.testimonial-box .list .rgt h5{
	font-weight: normal;
  margin-top: 10px;
  font-size: 18px;
}
.testimonial-box .list .rgt p{
	
	color: #A9A9A9;
}

.testimonial-box{
	padding-right: 30px;
}
.testimonial-box-bg .tt{
font-size: 26px;
  margin-bottom: 30px;
}

.footer-bg{
background: #101026;
  padding: 60px 30px;
  color: #fff;
  border-top: 1px solid rgba(255,255,255,0.1);

}

.footer-bg .address-bg p{
	color: #A9A9A9;
}
.footer-list ul{
	margin:0;
	padding:0;
	list-style:none;
}
.footer-list ul li a{
	color: #A9A9A9;
}


.feature-box-list.sql-ft .list .lft .img{
	width: 200px;
  height: 200px;
  filter:none;
}

.feature-box-list.sql-ft .list .lft {
width: 200px;
  height: 200px;
  margin-right: 25px;
  margin: 0 auto;
    margin-bottom: 0px;
  margin-bottom: 15px;
}
.feature-box-list.sql-ft .list{
flex-direction: column;
  text-align: center;
}


.feature-box-list.sql-ft .list .rgt h5{
	font-size: 18px;
	margin-top: 0px;
  font-weight: normal;
}

.feature-box-list.sql-ft .list .rgt p {
 color: #ccc;
  font-weight: bold;
  font-size: 18px;
}


.hero-counter-bg .counter-bg.spl-bg{
	flex-direction: column;
}


.hero-about-us.spl-cnt{
	padding: 60px 30px 60px 30px;
}

.feature-box-list.sql-ft.hgt-box{
	float: left;
  margin-right: 30px;z-index: 1;
  position: relative;
}
.feature-box-list.sql-ft.hgt-box .list .rgt h5 {
  font-size: 16px;
}
.feature-box-list.sql-ft.hgt-box .list .rgt p {
  color: #000;
  font-size: 16px;
}

.feature-box-list.sql-ft.hgt-box .list .rgt h5.hgt-adj {
  width: 300px;
  line-height: 25px;
}


.quotes-bg {
background: #101026;
  padding: 45px 45px 60px 45px;
  border-radius: 8px;
  font-size: 50px;
  line-height: 1.2;
  z-index:1;
  color: #fff;
  position:relative;
  margin-left:15px;
}

.quotes-bg::before{
position: relative;
  content: "''";
  font-size: 60px;
}

.quotes-bg::after{
position: relative;
  content: "''";
  font-size: 60px;
}

.quotes-bg b{
	font-size: 18px;
  position: absolute;
  bottom: 20px;
  right: 30px;
  text-decoration: underline;
}


.ad-spl-ft{
	margin-top: 30px;
  color: #fff;
}
.feature-box-list.sql-ft.ad-spl-ft.hgt-box .list{
	flex-direction:row;
}
.feature-box-list.sql-ft.ad-spl-ft.hgt-box .list .rgt  {
	margin-left: 30px;
}
.feature-box-list.sql-ft.ad-spl-ft.hgt-box .list .rgt h5 {
font-size: 40px;
  font-weight: normal;
  margin-top: 20px;
  margin-left: 0px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(255,255,255,0.4);
  padding-bottom: 20px;
}
.feature-box-list.sql-ft.ad-spl-ft.hgt-box .list .rgt h6 {
font-size: 21px;
  font-weight: bold;
}

.feature-box-list.sql-ft.ad-spl-ft.hgt-box .list .rgt p {
color: #ccc;
  font-size: 18px;
  font-weight: normal;
  text-align: left;
  margin-left: 0px;
}

.feature-box-list.sql-ft.ad-spl-ft .list .lft .img {
  border: 5px solid rgba(255,255,255,0.2);
}

.feature-box-list.sql-ft.ad-spl-ft .list{
	text-align:left;
}


.hero-banner-intro .quotes-tt{
font-size: 18px;
  font-weight: normal;
  line-height: 26px;
  text-align: justify;
  border: 1px solid #fff;
  padding: 15px 15px;
  border-radius: 8px;
}

.hero-banner-intro .quotes-tt b{
	font-size:14px;
	font-weight:normal;
}

.hero-counter-bg.spl-ft  .counter-bg .counter h4 {
  font-size: 16px;
  font-weight: bold;
  font-weight: normal;
  color: #cff7ff;
  margin-bottom:0px;
}

.hero-counter-bg.spl-ft .counter-bg .counter{
	padding: 20px;
	border: 1px solid rgba(255,255,255,0.2);
text-align: left;
}


.custom-nav-pills{
	margin-bottom: 30px;
}

.custom-nav-pills .nav-pills .nav-link{
  color: #cff7ff;
  background-color: #101026;
  border:1px solid rgba(255,255,255,0.2);
  margin-right:10px;
}

.custom-nav-pills .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: #444;
  background-color: #cff7ff;
}

.hero-banner-slider.spl-ft::before {
position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  content: "";
  z-index: 1;
  background: rgba(16, 16, 38, 0.5);
}

.vision-bg{
	background: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 30px 30px 15px 30px;
  margin-left:15px;
  margin-bottom:30px;
}

.vision-bg .cnt{
padding: 0px 0px;
color: #555;
}
.vision-bg 	.hd h3{
font-size: 26px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.vision-bg 	.hd h3 img{
height: 30px;
  margin-right: 10px;
}

.vision-bg .cnt .al-img{
width:100%;	
margin-bottom:15px;
}
.hero-about-us.spl-hgt {
  padding: 0px 30px 15px 30px;
}
.hero-about-us.spl-hgt .cnt {
  padding: 0px 0px;
  font-size: 16px;
  line-height: 26px;
  padding-left: 15px;
}

.hero-about-us.spl-hgt .img-tg{
width: 100%;
  border-radius: 8px;
}
.hero-about-us.spl-hgt.ad-spl-ht .cnt{	
	padding-left:0px;
	padding-right: 15px;
}

.hero-about-us.spl-hgt.ad-spl-ht .img-tg{
	margin: 10px 0px;
  height: 150px;
  object-fit: cover;
}
.hero-about-us.ad-spl-ht .tt {
  font-size: 20px;
}
.hero-about-us.spl-hgt.ad-spl-ht{
background: #f9f9f9;
  padding-top: 30px;
}


.hero-counter-bg.spl-ft .counter-bg .counter h4 a{
	color: #cff7ff;
}

.hero-about-us p,.hero-about-us ul{
	color: #555;
  text-align: justify;
}

.hero-counter-bg.spl-ft .counter-bg .counter p{
	margin-top:10px;	

}
.testimonial-box-bg.custom-box-bg{
	padding: 40px 30px 60px 30px;
}
.testimonial-box-bg.custom-box-bg .tt {
  font-size: 18px;
  margin-bottom: 15px;
  margin-top: 30px;
}

.hero-counter-bg.spl-ft .counter-bg .counter h4 {
  font-size: 16px;
}

.ml-null{
	margin-left:0px;
}

.appln-bg{
width: 100%;
  border-radius: 8px;
  background: #101026;
  color: #fff;
  padding: 30px;
  margin-bottom: 20px;
}
.appln-bg h2{
font-size: 26px;
margin-bottom:15px;
}

.appln-bg .cnt{
border-top: 1px solid rgba(255,255,255,0.3);
  padding: 20px 0px 25px 0px;	
}
.appln-bg .cnt h4{ 
font-size: 18px;
  margin-bottom: 10px;
}


.hero-about-us .spl-pl{
	border-bottom: 1px solid rgba(0,0,0,0.1);
  padding-bottom: 15px;
}

.hero-about-us .spl-tt{
color: #101026;
  font-weight: bold;
  font-size: 18px;
}

.cm-navbar-menu-list ul li .sub-menu{
position: absolute;
  background: #000016;
  height: auto;
  z-index: 1000;
  padding: 0px ;
  top: 35px;
  display: none;
  min-width: 300px;
}
.cm-navbar-menu-list ul li .sub-menu li{
	margin-right: 0px;
	width: 100%;
}

.cm-navbar-menu-list ul li .sub-menu li a{
	display:inline-block;
	width:100%;
	padding:5px 15px;
	border-bottom:1px solid rgba(255,255,255,0.15);
}

.cm-navbar-menu-list ul li:hover .sub-menu{
	display:block;
}

.cm-navbar-menu-list ul li .sub-menu li a {
	padding: 10px 15px 10px 15px;
}

.cm-navbar-menu-list ul li .sub-menu li:last-child a{
	border:0px;
}
.cm-navbar-menu-list ul li .sub-menu li{
	position:relative;
}
.cm-navbar-menu-list ul li .sub-menu li .sub-more-menu{
	position: absolute;
  background: #000016;
  height: auto;
  z-index: 1000;
  padding: 0px;
top: 0px;
  display: none;
  min-width: 300px;
  left: 270px;
}

.cm-navbar-menu-list ul li .sub-menu li .sub-more-menu li a{
	display:inline-block;
	width:100%;
	padding:5px 15px;
	border-bottom:1px solid rgba(255,255,255,0.15);
}

.cm-navbar-menu-list ul li .sub-menu li:hover .sub-more-menu {
	display:block;
}

.hamburger{
position: absolute;
  right: 10px;
  padding: 5px 5px 5px 5px;
  border: 1px solid;
   
  border-color: #101026;
  color: #101026;
  font-size: 24px;
  border-radius: 30px;
  line-height: 0;
  display:none;
}

.hero-banner-slider.spl-cnt{
background-size: cover;
  background-position: top;
}
.hero-banner-slider.spl-cnt .hero-banner-intro{
	margin-top: 15%;
  margin-bottom: 15%;
}

.cm-navbar-menu-list ul li span{
	color:#fff;
	padding: 15px 0px;
	cursor:pointer;
}

.feature-box-list.sql-ft::before{
	background: rgba(16, 16, 38, 0.8);
}

.appln-bg .btn-primary{
  margin-right:10px;
}
.feature-box-list.sql-ft.ad-spl-ft.hgt-box .list .rgt span {		
  text-decoration: underline;
font-weight: bold;
font-size: 20px;
}
.whatspp-icon{
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 1;
  background: #fff;
  padding: 10px;
  color: #000;
  border-radius: 4px 4px 0px 0px;
  box-shadow: 2px 2px 4px #000;
  text-decoration: none !important;
}
.whatspp-icon img{
  width:40px;
}